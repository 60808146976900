import {
  Mesh,
  BoxGeometry,
  SpotLight,
  PointLight,
  MeshPhongMaterial,
  Box3,
} from 'three';
import Item from './Item';
import Shadow from './Shadow';
import { GRID, ROLE_Z } from '@/utils/Config';
import FaceMaterial from './utils/FaceMaterial';
export default class Solid extends Item {
  constructor(params) {
    const {
      id,
      resource,
      x,
      y,
      width,
      height,
      sceneDepth,
      rotation,
      meta,
      scene,
      shadowDropMap,
      baseColor,
      baseTexture,
    } = params;

    super(params);
    this._type = 'solid';
    this.baseHeight = 0;
    this.shadowDropMap = params.shadowDropMap;
    this.baseColor = baseColor;

    this.baseTexture = baseTexture;
    this.setShadow();
    this.setBase();

    this.spotLight.distance = this.sceneDepth;
    this.spotLight.decay = 1.8;
    this.spotLight.position.set(0, 180, this.sceneDepth);
  }

  render() {
    const item = this.resource;

    item.position.set(0, GRID / 2, ROLE_Z - this.meta.offsetY);
    this.add(item);
    return item;
  }
  setBase() {
    let _box = new Box3().setFromObject(this._obj);
    _box.disableRaycast = true;

    const baseHeight = this._obj.position.z - (_box.max.z - _box.min.z) / 2;

    const size = { w: this.width + GRID, h: this.height + GRID, d: baseHeight };
    const geometry = new BoxGeometry(size.w, size.h, size.d);

    let materials;
    if (this.baseTexture) {
      materials = FaceMaterial({
        texture: this.baseTexture,
        size: { ...size },
        ratio: 100,
      });
    } else {
      materials = new MeshPhongMaterial({
        color: this.baseColor,
        shininess: 30,
      });
    }

    const base = new Mesh(geometry, materials);

    base.position.x = 0;
    base.position.y = GRID / 2;

    base.position.z = baseHeight / 2;
    // base.receiveShadow = true;
    // base.castShadow = true;
    const shadow = new Shadow({
      obj: base,
      zoom: 1.6,
      offsetY: 2,
      opacity: 0.3,
    });
    this.add(shadow);
    this.add(base);
  }
  hitten() {
    this.toggleLight(6);
  }
  setDefaultLight() {
    this.spotLight.intensity = 2;
  }
  setShadow() {
    const shadow = new Shadow({ obj: this._obj });

    this.add(shadow);
  }
}
