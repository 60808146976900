// import Stats from 'stats.js'

// const stats = new Stats()
// stats.showPanel(0) // 0: fps, 1: ms, 2: mb, 3+: custom
// document.body.appendChild(stats.dom)

export default ({ status, controls, dialog, renderer, camera, scene }) => {
  let requestAnimationId;
  const start = () => {
    // stats.begin()

    requestAnimationId = window.requestAnimationFrame(start);
    if (status.mode === 'dialoging' && dialog.state.component !== 'MapControl')
      return;
    if (status.mode === 'controling') return;
    // if (dialog.state.show && dialog.state.component !== 'MapControl') return;
    controls.updateTWEEN();
    const controling = controls.updateControl(status);
    // 這裡要加上影片判斷

    renderer.render(scene, camera);
    // stats.end()
  };

  const stop = () => {
    cancelAnimationFrame(requestAnimationId);
  };
  const id = () => {
    return requestAnimationId;
  };
  // update();
  return { start, stop, id };
};
