import {
  BoxGeometry,
  PlaneGeometry,
  Mesh,
  MeshPhongMaterial,
  TextureLoader,
  RepeatWrapping,
} from 'three';
import { GRID, ROLE_Z, ROOM_COLOR, FLOOR_HEIGHT } from '@/utils/Config';
import FaceMaterial from './utils/FaceMaterial';
export default class Floor extends Mesh {
  constructor(params) {
    const { texture, color, width, height } = params;
    super();

    // texture.wrapS = texture.wrapT = RepeatWrapping;
    // texture.repeat.set(6, 3);
    this.size = {
      w: width - 10,
      h: height - 10,
      d: FLOOR_HEIGHT,
    };
    this.geometry = new BoxGeometry(this.size.w, this.size.h, this.size.d);

    this.material = FaceMaterial({
      texture,
      color,
      size: this.size,
      faces: ['u', 'b'],
      ratio: 100,
    });

    this.position.x = 0;
    this.position.y = 0;
    this.position.z = FLOOR_HEIGHT / -2 + 1;
    this._type = 'floor';
    this.receiveShadow = false;
    this.castShadow = false;
  }
}
