import { BoxGeometry, Mesh, MeshPhongMaterial } from 'three';
import { GRID, ROLE_Z, FLOOR_HEIGHT } from '@/utils/Config';
import FaceMaterial from './utils/FaceMaterial';
// import Shadow from './Shadow';
export default class Wall extends Mesh {
  constructor(params) {
    const { x, y, width, height, sceneDepth, meta, color, texture } = params;
    super();
    const size = { w: width, h: height, d: sceneDepth + FLOOR_HEIGHT * 2 };
    this.geometry = new BoxGeometry(size.w, size.h, size.d);
    let materials;

    if (texture) {
      materials = FaceMaterial({
        texture: texture,
        size: { ...size },
        ratio: 100,
      });
    } else {
      materials = new MeshPhongMaterial({
        color,
        shininess: 50,
      });
    }

    this.material = materials;
    this.material.needsUpdate = true;
    this._type = 'wall';
    this.position.x = x;
    this.position.y = y;
    this.position.z = sceneDepth / 2;
    // this.castShadow = true;
    // this.receiveShadow = true;

    // this.rotation.z += Math.round(angle * Math.PI); //-逆時針旋轉,+順時針
  }
}
