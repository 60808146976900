import {
  BoxGeometry,
  Mesh,
  MeshPhongMaterial,
  MeshStandardMaterial,
  DoubleSide,
  PlaneGeometry,
  Math,
  RepeatWrapping,
} from 'three';
import { GRID, ROLE_Z, ROOM_COLOR, FLOOR_HEIGHT } from '@/utils/Config';
import { CSG } from 'three-csg-ts';
import Shadow from './Shadow';
import FaceMaterial from './utils/FaceMaterial';

export default class Outer extends Mesh {
  constructor(params) {
    const {
      x,
      y,
      width,
      sceneDepth,
      rotation,
      color,
      exterior,
      texture,
      scene,
    } = params;

    super();

    this.color = color;
    this.exterior = exterior;
    this.size = { w: width, h: GRID, d: sceneDepth + FLOOR_HEIGHT * 2 + GRID };
    this.geometry = new BoxGeometry(this.size.w, this.size.h, this.size.d);

    this.texture = texture;

    this.material = FaceMaterial({
      texture: this.texture,
      color: this.color,
      size: { ...this.size },
      faces: ['b'],
      ratio: 100,
    });

    // this.material[4].texture.color = exterior;
    // this.castShadow = true;
    // this.receiveShadow = true;
    this.rotation.z += Math.degToRad(rotation);
    this._type = 'outer';
    this.position.x = x;
    this.position.y = y;
    this.position.z = this.size.d / 2 - FLOOR_HEIGHT;

    // scene.add(this);
  }

  createDoor(params) {
    const { width, height, depth, x, y, z } = params;
    const door = new Mesh(
      new BoxGeometry(width, height + FLOOR_HEIGHT, depth),
      new MeshPhongMaterial({
        color: this.color,
        shininess: 50,
      })
    );
    this.updateMatrix();
    door.position.x = x;
    door.position.y = y;
    door.position.z = z - FLOOR_HEIGHT;
    door.rotation.y = Math.degToRad(180);
    // door.rotation.x = Math.degToRad(180);
    door.updateMatrix();
    door._type = 'outer';

    // const mesh = new Mesh(
    //   new PlaneGeometry(this.size.w, this.size.h, this.size.d),
    //   new MeshPhongMaterial({
    //     color: this.exterior,
    //     shininess: 50,
    //   })
    // );

    this.updateMatrix();
    const _door = CSG.subtract(this, door);

    _door.geometry.clearGroups();
    _door.geometry.addGroup(0, 27, 1);
    _door.geometry.addGroup(27, 39, 1);
    _door.geometry.addGroup(66, 39, 2);
    _door.geometry.addGroup(105, 45, 1);
    _door.geometry.addGroup(150, 0, 1);

    _door.material[1].color.set(this.exterior);
    const mat = _door.material[2];

    if (this.texture) {
      const map = this.texture.clone();
      map.wrapS = RepeatWrapping;
      map.wrapT = RepeatWrapping;
      mat.color.set('#fff');

      map.repeat.set(this.size.w / 100, this.size.d / 100);
      mat.map = map;

      mat.map.needsUpdate = true;
    } else {
      mat.color.set(this.color);
    }

    // _door.rotation.z += Math.degToRad(180);
    return _door;
  }
}
